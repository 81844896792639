import AzureAuthService from "@/services/AzureAuthService";
import axios from "axios";

export const RequestInterceptor = (req) => {
    let token = localStorage.getItem("token");
    if (token) {
        req.headers.Authorization = "Token " + token;
        axios.defaults.headers.common["Authorization"] = "Token " + token;
    }
    return req;
};

export default RequestInterceptor;
