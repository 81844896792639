import { maxLength, required } from "vuelidate/lib/validators";
import { Answer } from "@/models/Answer";
import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { Competence } from "@/models/Competence";
import { Person } from "@/models/Person";
import { Questionnaire } from "@/models/Questionnaire";
import { Threat } from "@/models/Threat";
import { User } from "@/models/User";
import Signature from "./Signature";
import moment from "moment";
import { QUESTION_INPUT_TYPES, PROTOCOL_STATUS_TYPES } from "../constants";
import QuestionnaireCategory from "./QuestionnaireCategory";

export class Protocol extends BaseModel {
  constructor({
    id = null,
    protocol_date = moment().format("YYYY-MM-DD"),
    creator = null,
    responsible = null,
    reporter = null,
    participants = null,
    department = null,
    place = null,
    status = 0,
    points,
    company = {},
    questionnaire = {},
    category = null,
    answers = [],
    survey = null,
    token = null,
    threats = [],
    competencies = [],
    readreceipt_active = false,
    external_url = null,
    group_id,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10,
    creation_date,
    latest_change,
    visible_to = [],
    visible_to_user_groups = [],
    visible_to_departments = [],
    parent,
    parent_answer,
    parent_info,
    children = [],
    receiver,
    viewed,
    signed,
    signatures = [],
    signature_count,
    signature_approved_count,
    signature_rejected_count,
    tags = []
  }) {
    super();

    this.id = id;
    this.protocol_date = protocol_date ? moment(protocol_date).format("YYYY-MM-DD") : null;
    this.creator = creator ? new User(creator) : null;
    this.responsible = responsible ? new Person(responsible) : null;
    this.reporter = reporter ? new Person(reporter) : null;
    this.participants = participants;
    this.department = department;
    this.place = place;
    this.status = status;
    this.points = points;
    this.questionnaire = new Questionnaire(questionnaire);
    this.category = category ? new QuestionnaireCategory(category) : null;
    this.survey = survey;
    this.token = token;
    this.company = new Company(company);

    this.answers = answers.map((a) => new Answer(a));
    this.threats = threats.map((t) => new Threat(t));
    this.competencies = competencies.map((c) => new Competence(c));
    this.creation_date = moment(creation_date);
    this.latest_change = moment(latest_change);
    this.visible_to = visible_to;
    this.visible_to_user_groups = visible_to_user_groups;
    this.visible_to_departments = visible_to_departments;
    this.readreceipt_active = readreceipt_active;
    this.parent = parent ? parent : null;
    this.parent_answer = parent_answer ? parent_answer : null;
    this.parent_info = parent_info ? parent_info : null;
    this.children = children.map((i) => new Protocol(i));
    this.receiver = receiver ? new Person(receiver) : null;
    this.viewed = viewed;
    this.signed = signed;
    this.signatures = signatures.map((s) => new Signature(s));
    this.external_url = external_url;
    this.group_id = group_id;
    // Generic fields...
    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;
    this.field6 = field6;
    this.field7 = field7;
    this.field8 = field8;
    this.field9 = field9;
    this.field10 = field10;
    this.signature_count = signature_count;
    this.signature_approved_count = signature_approved_count;
    this.signature_rejected_count = signature_rejected_count;
    this.tags = tags;

    this._showResponsibleSelect = this.isNew || this.responsible || (!this.responsible && !this.participants); // Keep track of manual switching of show responsible user
  }

  static get validations() {
    const validations = {
      participants: {
        maxLength: maxLength(255)
      },
      place: {
        maxLength: maxLength(255)
      },
      responsible: {},
      reporter: {}
    };

    return validations;
  }

  get title() {
    return `${this.questionnaire.name} - ${this.protocol_date}`;
  }

  get responsibleName() {
    return this.responsible ? this.responsible.fullName : this.participants;
  }

  get reporterName() {
    return this.reporter ? this.reporter.fullName : "";
  }

  get showResponsibleSelect() {
    return this._showResponsibleSelect;
  }

  isCreatedBy(user) {
    return this.creator && this.creator.id === user.id;
  }

  isResponsible(user) {
    return this.responsible && this.responsible.user && this.responsible.user.id === user.id;
  }

  toggleShowResponsibleSelect() {
    this._showResponsibleSelect = !this._showResponsibleSelect;
  }

  get applicableFeedbackTexts() {
    let feedbackTexts = [];
    const points = this.getPoints();

    feedbackTexts = this.questionnaire.feedback_texts.filter((ft) => {
      let startOk = ft.start_points === null || points >= ft.start_points;
      let endOk = ft.end_points === null || points <= ft.end_points;
      return startOk && endOk;
    });

    return feedbackTexts;
  }

  get allOptionQuestionsAnswered() {
    let optionQuestionAnswers = this.answers.filter(
      (a) =>
        a.question.conditionsFulfilled(this.answers) &&
        (a.question.input_type === QUESTION_INPUT_TYPES.CHECKBOX ||
          a.question.input_type === QUESTION_INPUT_TYPES.CHECKLIST ||
          a.question.input_type === QUESTION_INPUT_TYPES.RADIO_BUTTON ||
          a.question.input_type === QUESTION_INPUT_TYPES.SELECT_LIST)
    );
    return optionQuestionAnswers.every((a) => a.value_option);
  }

  getPoints() {
    let points = 0;
    this.answers.forEach((a) => {
      if (a.value_option && a.value_option.points) {
        points += a.value_option.points;
      }
    });

    return points;
  }

  generateAnswers() {
    let answers = [];
    this.questionnaire.questions.forEach((question) => {
      let newAnswer = new Answer({ question });
      answers.push(newAnswer);
    });
    this.answers = answers;
  }

  get editAllowed() {
    return this.status <= PROTOCOL_STATUS_TYPES.DONE;
  }
}

export default Protocol;
